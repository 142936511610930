<template>
  <l-mask>
    <div class="rank-panel" :style="panelStyle">
      <el-carousel type="card" :autoplay="false" :initial-index="index" :height="config.height * 0.9 + 'px'">
        <el-carousel-item v-for="(item, index) in rankCarousel" :key="index">
          <carousel-item :data="item"></carousel-item>
        </el-carousel-item>
      </el-carousel>
    </div>
  </l-mask>
</template>

<script>
import carouselItem from './rankCarousel/carousel-item'
export default {
  components: { 'carousel-item': carouselItem },
  data() {
    return {
      title: '', // 内置参数,误删
      visible: true, // 内置参数,误删
      monitorData: null,
      config: null,
      year: null,
      index: 1,
      panelStyle: null,
      rankCarousel: []
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.panelStyle = {
        width: this.config.width - this.config.unit * 2 + 'px',
        top: '70px',
        'margin-left': (0 - (this.config.width - this.config.unit * 2)) / 2 + 'px'
      }
      if (this.monitorData.isEGroup) {
        this.rankCarousel.push({
          index: 1,
          year: this.year,
          title: '企业提交量',
          col1Text: '企业名称',
          col1Code: 'enterpriseName',
          col2Text: '提交量',
          col2Code: 'submitCount',
          list: this.monitorData.rank_enterprise
        })
        this.rankCarousel.push({
          index: 2,
          year: this.year,
          title: '企业实施量',
          col1Text: '企业名称',
          col1Code: 'enterpriseName',
          col2Text: '实施量',
          col2Code: 'effectCount',
          list: this.monitorData.rank_enterprise
        })
        this.rankCarousel.push({
          index: 3,
          year: this.year,
          title: '员工提交量',
          col1Text: '员工名称',
          col1Code: 'fullName',
          col2Text: '提交量',
          col2Code: 'submitCount',
          list: this.monitorData.rank_user
        })
        this.rankCarousel.push({
          index: 4,
          year: this.year,
          title: '员工实施量',
          col1Text: '员工名称',
          col1Code: 'fullName',
          col2Text: '实施量',
          col2Code: 'effectCount',
          list: this.monitorData.rank_user
        })
      } else {
        this.rankCarousel.push({
          index: 1,
          year: this.year,
          title: '部门提交量',
          col1Text: '部门名称',
          col1Code: 'departName',
          col2Text: '提交量',
          col2Code: 'submitCount',
          list: this.monitorData.rank_depart
        })
        this.rankCarousel.push({
          index: 2,
          year: this.year,
          title: '部门整改量',
          col1Text: '部门名称',
          col1Code: 'departName',
          col2Text: '整改量',
          col2Code: 'effectCount',
          list: this.monitorData.rank_depart
        })
        this.rankCarousel.push({
          index: 3,
          year: this.year,
          title: '员工提交量',
          col1Text: '员工名称',
          col1Code: 'fullName',
          col2Text: '提交量',
          col2Code: 'submitCount',
          list: this.monitorData.rank_user
        })
        this.rankCarousel.push({
          index: 4,
          year: this.year,
          title: '员工整改量',
          col1Text: '员工名称',
          col1Code: 'fullName',
          col2Text: '整改量',
          col2Code: 'effectCount',
          list: this.monitorData.rank_user
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.rank-panel {
  position: absolute;
  left: 50%;
  z-index: 1000;
}

.rank-panel .el-carousel__item {
  border-radius: 20px;
  background-color: #282839;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}
</style>
